<template>
  <div class="px-7 py-4">
    <div>
      <div class="d-flex">
        <p style="font-size: 25px; font-family: Lato">Overview</p>
        <v-spacer></v-spacer>
        <div class="d-flex justifiy-end">
          <v-btn
            text
            class="mr-2 text-capitalize"
            style="border: 1px solid #2c1963; border-radius: 8px"
            height="40"
            @click="viewPublicDonationPageHandler"
          >
            <v-icon color="#2C1963" left small>mdi-eye</v-icon>
            <span style="color: #2c1963">View public page</span>
          </v-btn>
          <v-btn
            text
            class="mr-2 text-capitalize"
            style="border: 1px solid #2c1963; border-radius: 8px"
            height="40"
            @click="
              toggleAddEditViewTeacherModal({
                show: true,
                id: selectedCampaignData.organizationData.id,
              })
            "
          >
            <v-icon small left color="#2C1963">mdi-pencil</v-icon>
            <span style="color: #2c1963">Edit public page</span>
          </v-btn>
          <v-btn
            style="border-radius: 8px"
            color="#2C1963"
            dark
            height="40"
            class="text-capitalize"
            @click="toggleShareFundraiserModal({ show: true })"
          >
            <v-icon left small>mdi-share-variant</v-icon>
            <span class="ma-auto" style="color: white">Share public page</span>
          </v-btn>
        </div>
      </div>
      <v-row>
        <v-col class="graphCol" cols="6">
          <v-card>
            <v-card-title style="font-size: 20px; font-weight: 700">
              <v-row>
                <v-col cols="6">
                  <p>
                    Total Funds Raised:
                    <span style="color: #067605"
                      >${{ overviewData.total_fund_raised }}</span
                    >
                  </p>
                </v-col>
                <v-col cols="6" class="text-right">
                  <p>
                    Goal Reached:
                    <span style="color: #38227a"
                      >{{ overviewData.fund_raise_percentage }}%</span
                    >
                  </p>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text>
              <line-chart
                :graph_data="overviewData.total_fund_raised_graph_data"
                v-if="loader"
              ></line-chart>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col class="graphCol" cols="6">
          <v-card>
            <v-card-title style="font-size: 20px; font-weight: 700">
              <v-row>
                <v-col cols="6">
                  <p>
                    Total Videos Watched:
                    <span style="color: #067605"
                      >{{ overviewData.total_videos_watched }}/
                      <span style="font-size: 17px">{{
                        overviewData.total_videos
                      }}</span></span
                    >
                  </p>
                </v-col>
                <v-col cols="6" class="text-right">
                  <p>
                    Goal Reached:
                    <span style="color: #38227a"
                      >{{ overviewData.wizfit_video_percentage }}%</span
                    >
                  </p>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text>
              <line-chart
                :graph_data="overviewData.total_videos_watched_graph_data"
                v-if="loader"
              ></line-chart>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <div>
      <v-row class="pt-15">
        <v-col cols="6" sm="3">
          <v-card class="cards py-0">
            <v-list>
              <v-list-item>
                <v-sheet
                  class="text-center pa-2"
                  rounded
                  height="42"
                  width="42"
                  color="rgba(211, 0, 36, 0.12)"
                  style="margin-top: 10px"
                >
                  <v-img
                    src="@/assets/DistrictCampaign/Dashboard/student.png"
                  ></v-img>
                </v-sheet>

                <v-list-item-content>
                  <v-list-item-title class="title">
                    <p style="margin-left: 10px">
                      {{ overviewData.total_students_registered }}
                    </p>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title
                    class="CardActionTitle py-0 px-0"
                    style="color: #757575"
                  >
                    Total Student Registrations
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
        <v-col cols="6" sm="3">
          <v-card class="cards py-0">
            <v-list>
              <v-list-item>
                <v-sheet
                  class="text-center pa-2"
                  rounded
                  height="42"
                  width="42"
                  color="rgba(6, 118, 5, 0.12)"
                  style="margin-top: 10px"
                >
                  <img
                    height="25"
                    src="@/assets/DistrictCampaign/Dashboard/totalFund.png"
                  />
                </v-sheet>

                <v-list-item-content>
                  <v-list-item-title class="title">
                    <p style="margin-left: 10px">
                      {{ overviewData.total_fund_raised }}
                    </p>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title
                    class="CardActionTitle py-0 px-0"
                    style="color: #757575"
                  >
                    Total funds raised
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
        <v-col cols="6" sm="3">
          <v-card class="cards py-0">
            <v-list>
              <v-list-item>
                <v-sheet
                  class="text-center pa-2"
                  rounded
                  height="42"
                  width="42"
                  color="rgba(23, 166, 140, 0.12)"
                  style="margin-top: 10px"
                >
                  <img
                    height="25"
                    src="@/assets/DistrictCampaign/Dashboard/averageDonation.png"
                  />
                </v-sheet>

                <v-list-item-content>
                  <v-list-item-title class="title">
                    <p style="margin-left: 10px">
                      {{ overviewData.average_donation }}
                    </p>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title
                    class="CardActionTitle py-0 px-0"
                    style="color: #757575"
                  >
                    Average Donation Amount
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
        <v-col cols="6" sm="3">
          <v-card class="cards py-0">
            <v-list>
              <v-list-item>
                <v-sheet
                  class="text-center pa-2"
                  rounded
                  height="42"
                  width="42"
                  color="rgba(0, 9, 237, 0.12)"
                  style="margin-top: 10px"
                >
                  <img
                    height="25"
                    src="@/assets/DistrictCampaign/Dashboard/watchedVideo.png"
                  />
                </v-sheet>

                <v-list-item-content>
                  <v-list-item-title class="title">
                    <p style="margin-left: 10px">
                      {{ overviewData.total_videos_watched }}
                    </p>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title
                    class="CardActionTitle py-0 px-0"
                    style="color: #757575"
                  >
                    Number of Videos Watched
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
        <v-col cols="6" sm="3">
          <v-card class="cards py-0">
            <v-list>
              <v-list-item>
                <v-sheet
                  class="text-center pa-2"
                  rounded
                  height="42"
                  width="42"
                  color="rgba(222, 173, 0, 0.12)"
                  style="margin-top: 10px"
                >
                  <img
                    height="25"
                    src="@/assets/DistrictCampaign/Dashboard/totalNumberStudent.png"
                  />
                </v-sheet>

                <v-list-item-content>
                  <v-list-item-title class="title">
                    <p style="margin-left: 10px">
                      {{ overviewData.active_fundraisers }}
                    </p>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title
                    class="CardActionTitle py-0 px-0"
                    style="color: #757575"
                  >
                    Number of Active Fundraisers
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>

        <v-col cols="6" sm="3">
          <v-card class="cards py-0">
            <v-list>
              <v-list-item>
                <v-sheet
                  class="text-center pa-2"
                  rounded
                  height="42"
                  width="42"
                  color="rgba(231, 111, 0, 0.12)"
                  style="margin-top: 10px"
                >
                  <img
                    height="25"
                    src="@/assets/DistrictCampaign/Dashboard/noofDonor.png"
                  />
                </v-sheet>

                <v-list-item-content>
                  <v-list-item-title class="title">
                    <p style="margin-left: 10px">
                      {{ overviewData.total_donor }}
                    </p>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title
                    class="CardActionTitle py-0 px-0"
                    style="color: #757575"
                  >
                    Number of donors
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
        <v-col cols="6" sm="3">
          <v-card class="cards py-0">
            <v-list>
              <v-list-item>
                <v-sheet
                  class="text-center pa-2"
                  rounded
                  height="42"
                  width="42"
                  color="rgba(0, 133, 255, 0.12)"
                  style="margin-top: 10px"
                >
                  <img
                    height="25"
                    src="@/assets/DistrictCampaign/Dashboard/noofDonation.png"
                  />
                </v-sheet>

                <v-list-item-content>
                  <v-list-item-title class="title">
                    <p style="margin-left: 10px">
                      {{ overviewData.total_donation }}
                    </p>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title
                    class="CardActionTitle py-0 px-0"
                    style="color: #757575"
                  >
                    Number Of Donations
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
        <v-col cols="6" sm="3">
          <v-card class="cards py-0">
            <v-list>
              <v-list-item>
                <v-sheet
                  class="text-center pa-2 py-3"
                  rounded
                  height="42"
                  width="42"
                  color="rgba(157, 0, 231, 0.12)"
                  style="margin-top: 10px"
                >
                  <img
                    height="20"
                    width="25"
                    src="@/assets/DistrictCampaign/Dashboard/noofShare.png"
                  />
                </v-sheet>

                <v-list-item-content>
                  <v-list-item-title class="title">
                    <p style="margin-left: 10px">
                      {{ overviewData.total_shares }}
                    </p>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title
                    class="CardActionTitle py-0 px-0"
                    style="color: #757575"
                  >
                    Number of Shares
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <div>
      <p class="tableHeading">WizFit video showcase progress ----</p>
      <video-show-case v-if="loader"></video-show-case>
      <p class="tableHeading">WizFit videos ----</p>
      <video-section-peteacher></video-section-peteacher>
      <p class="tableHeading">Class wide Leaderboard ----</p>
      <div>
        <class-leaderboard v-if="loader"></class-leaderboard>
      </div>
    </div>
    <br /><br /><br />
    <share-fundraiser-modal></share-fundraiser-modal>
  </div>
</template>
<script>
import Axios from "@/api/BaseAxios";
import { API_PETEACHER_OVERVIEW } from "@/constants/APIUrls";
import { mapGetters, mapActions } from "vuex";
import Helper from "@/helper";
import ShareFundraiserModal from "@/components/PublicDonationPages/ShareFundraiserModal/ShareFundraiserModal";
export default {
  name: "Dashboard",
  components: {
    ShareFundraiserModal,
    LineChart: () => import("@/components/Graphs/LineChart.vue"),
    VideoShowCase: () => import("./VideoShowcaseTable.vue"),
    ClassLeaderboard: () =>
      import("@/components/LeaderboardDataTables/ClasswiseLeaderboard"),
    VideoSectionPeteacher: () =>
      import("./VideoSectionPeteacher/VideoSectionPeteacher"),
  },
  data() {
    return {
      loader: false,
      overviewData: {},
      graph_data: {
        label: ["aug-1", "aug-2", "aug-3", "aug-4"],
        value: [10, 50, 100, 20],
      },
    };
  },
  computed: {
    ...mapGetters({
      selectedCampaign: "campaign/getSelectedCampaign",
    }),
    selectedCampaignData() {
      return this.selectedCampaign;
    },
  },
  watch: {},
  methods: {
    ...mapActions({
      toggleAddEditViewTeacherModal: "teacherManagement/toggleTeacher",
      toggleShareFundraiserModal:
        "publicDonationPage/toggleShareFundraiserModal",
      toggleSchoolId: "teacherManagement/toggleSchool",
    }),
    getOverviewData() {
      const successHandler = (res) => {
        this.overviewData = res.data.overview_data;
        this.toggleSchoolId(res.data.overview_data.school_id);
        this.loader = true;
      };
      const failureHandler = (res) => {
        console.log(res);
      };
      let formData = {};
      formData["campaign_id"] = this.selectedCampaignData.campaignData.id;
      formData["teacher_id"] = this.selectedCampaignData.organizationData.id;
      Axios.request_GET(
        API_PETEACHER_OVERVIEW,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    viewPublicDonationPageHandler() {
      const PANEL = "teacher";
      const id = this.selectedCampaign.organizationData.id;
      const sub_domain = this.selectedCampaign.campaignData.sub_domain;
      const queryParam = PANEL + "=" + id;
      Helper.openPublicDonationPage(sub_domain, queryParam);
    },
  },
  mounted() {
    this.getOverviewData();
    console.log(this.selectedCampaign, "this is selected campaign");
  },
  created() {
    this.$root.$refs.peteacherDashboard = this;
  },
};
</script>
<style scoped>
* {
  font-family: Lato;
}
.graphCol.col {
  max-height: 640px;
}
.v-list-item__title.title {
  position: relative;
  top: 15px;
}
.tableHeading {
  color: #2c1963;
  font-size: 30px;
  font-family: Roboto Slab;
  font-weight: 700;
  padding-top: 70px;
}
.line {
  height: 2px;
  background-color: #2c1963;
  width: 20px;
}
</style>
